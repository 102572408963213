<template>
  <div>
    <div class="flex-def flex-cCenter flex-zBetween" style="margin-bottom: 1rem">
      <div class="y-desc" style="font-size: 1rem">文章编辑</div>
      <el-button @click="$router.back()" type="text" size="mini">返回列表</el-button>
    </div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form>
            <el-form-item>
              <y_editor v-model="form.detail"></y_editor>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form label-width="6rem">
            <el-form-item label="排序">
              <el-input-number v-model="form.sort"></el-input-number>
            </el-form-item>
            <el-form-item label="所属分类">
              <el-select v-model="form.cate_id">
                <el-option
                    v-for="item in cate_options"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标题">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="缩略图">
              <y_upload_img v-model="form.pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="分享描述">
              <el-input v-model="form.share_desc"></el-input>
              <div class="y-desc">为空时默认填充文章标题</div>
            </el-form-item>
            <el-form-item label="分享图片">
              <y_upload_img v-model="form.share_pic"></y_upload_img>
              <span class="y-desc">为空时默认填充缩略图</span>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_editor from "@/components/y-form/y-editor/y_editor";
export default {
  name: "edit",
  components:{
    y_upload_img,
    y_editor,
  },
  data() {
    return {
      edit_id:0,
      form:{
        sort:0,
        cate_id:0,
        title:"",
        pic:"",
        share_pic:"",
        share_desc:"",
        detail:""
      },
      cate_options:[],
    }
  },
  mounted() {
    this.loadCate(()=>{
      if (this.$route.query.id){
        this.edit_id = parseInt(this.$route.query.id);
        this.load();
      }else {
        this.form.cate_id = this.cate_options[0]?.id;
      }
    });
  },
  methods: {
    loadCate(cb=false){
      this.$u.api.album.article.cateAll().then(res=>{
        this.cate_options = res;
        cb && cb();
      }).catch(()=>{
        cb && cb();
      })
    },
    load() {
      this.$u.api.album.article.detail({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.album.article.edit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    },
  }
}
</script>

<style scoped>

</style>